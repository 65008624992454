import React, { useState,useContext } from 'react';
import Header from '../../Views/Header/Header';
import './Registros.module.css'; // Asegúrate de importar tu archivo CSS
import '@fortawesome/fontawesome-free/css/all.min.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {DataContext} from '../ConfiState/DataContext'
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL; 
const SignInForm = () => {
  const navigate = useNavigate()
  const { state } = useContext(DataContext);
  const { carreras } = state;
  const [formData, setFormData] = useState({
    idCarrera: null,
    firstName: '',
    lastName: '',
    dni: '',
    gender: '',
    age: '',
    email: '',
    carrera: ''
  });

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleClickRegistrarStudent = async (e) => {
    e.preventDefault();
    const carreraselect = carreras.find(u => u.nombre === formData.carrera);
    formData.idCarrera = carreraselect ? carreraselect.idCarrera : null;
    
    const { carrera, ...studentData } = formData;

    try {
      const response = await axios.post(apiUrl+'/Student', studentData);
      Swal.fire('Registrado!', 'Usuario registrado con éxito!', 'success');
      navigate("/student")
    } catch (error) {
      Swal.fire('Error!', 'Hubo un problema al registrar el usuario.', 'error');
    }
  };

  return (
    <form className="sign-in-form" onSubmit={handleClickRegistrarStudent}>
      <h2 className="title">Registrar Estudiante</h2>
      <div className="input-field">
        <i className="fas fa-user"></i>
        <input
          type="text"
          name="firstName"
          placeholder="First Name"
          value={formData.firstName}
          onChange={handleChange}
        />
      </div>
      <div className="input-field">
        <i className="fas fa-user"></i>
        <input
          type="text"
          name="lastName"
          placeholder="Last Name"
          value={formData.lastName}
          onChange={handleChange}
        />
      </div>
      <div className="input-field">
        <i className="fa-solid fa-address-card"></i>
        <input
          type="text"
          name="dni"
          placeholder="DNI"
          value={formData.dni}
          onChange={handleChange}
        />
      </div>
      <div className="input-field">
        <i className="fa-solid fa-venus-mars"></i>
        <select
          className='select'
          name="gender"
          value={formData.gender}
          onChange={handleChange}
        >
          <option value="" disabled>Género</option>
          <option value="Masculino">Masculino</option>
          <option value="Femenino">Femenino</option>
        </select>
      </div>
      <div className="input-field">
        <i className="fa-solid fa-clock"></i>
        <input
          type="text"
          name="age"
          placeholder="Age"
          value={formData.age}
          onChange={handleChange}
        />
      </div>
      <div className="input-field">
        <i className="fas fa-envelope"></i>
        <input
          type="text"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      <div className="input-field">
        <i className="fa-solid fa-graduation-cap"></i>
        <select
          className='select'
          name="carrera"
          value={formData.carrera}
          onChange={handleChange}
        >
          <option value="" disabled>Carrera</option>
          {carreras.map(carrera => (
            <option key={carrera.idCarrera} value={carrera.nombre}>
              {carrera.nombre}
            </option>
          ))}
        </select>
      </div>
      <input type="submit" value="Registrar" className="btn solid" />
    </form>
  );
};

const SignUpForm = () => {
  const [startDate, setStartDate] = useState(null);
  const { state } = useContext(DataContext);
  const { students } = state;
  const [formData, setFormData] = useState({
    dni: '',
    idEstudiante:null,
    MotivoPago: '',
    Monto: '',
    fecha: '',
    estadoPago: true,
    codigoPago:'',
    tipoPago:''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    setFormData({
      ...formData,
      fecha: date
    });
  };

  const handleClickRegistrarPago = async (e) => {
    e.preventDefault();
    const student = students.find(u => u.dni === formData.dni);
    formData.idEstudiante=student.idEstudiante
    formData.Monto=Number(formData.Monto)
    const {dni,tipoPago, ...pagoData } = formData;
    console.log("dataaaaaa:",formData)

    try {
      const response = await axios.post(apiUrl+'/registroPago', formData);
      console.log("Info que se enviará a la base de datos:",pagoData)
      console.log("Info del Pago registrado en la base de datos:",response)
      Swal.fire('Registrado!', 'Pago registrado con éxito!', 'success');
    } catch (error) {
      Swal.fire('Error!', 'Hubo un problema al registrar el Pago.', 'error');
    }
  };

  return (
    <form className="sign-up-form" onSubmit={handleClickRegistrarPago}>
      <h2 className="title">Registrar Pago</h2>
      <div className="input-field">
        <i className="fas fa-user"></i>
        <input
          type="text"
          name="dni"
          placeholder="DNI Estudiante"
          value={formData.dni}
          onChange={handleChange}
        />
      </div>
      <div className="input-field">
      <i className="fa-solid fa-arrow-right"></i>
        <input
          type="text"
          name="codigoPago"
          placeholder="Código de pago"
          value={formData.codigoPago}
          onChange={handleChange}
        />
      </div>
      <div className="input-field">
        <i className="fa-solid fa-arrow-right"></i>
        <select
          className='select'
          name="MotivoPago"
          value={formData.MotivoPago}
          onChange={handleChange}
        >
          <option value="" disabled selected>Concepto de Pago</option>
          <option value="Matricula I Ciclo">Matricula I Ciclo</option>
          <option value="Matricula II Ciclo">Matricula II Ciclo</option>
          <option value="Matricula III Ciclo">Matricula III Ciclo</option>
          <option value="Matricula IV Ciclo">Matricula IV Ciclo</option>
          <option value="Matricula V Ciclo">Matricula V Ciclo</option>
          <option value="Matricula VI Ciclo">Matricula VI Ciclo</option>
          <option value="Modulo I de Practicas">Modulo I de Practicas</option>
          <option value="Modulo II de Practicas">Modulo II de Practicas</option>
          <option value="Modulo III de Practicas">Modulo III de Practicas</option>
          <option value="Certificado de Estudio">Certificado de Estudio</option>
          <option value="Certificado de Ingles">Certificado de Ingles</option>
          <option value="Certificado de Egresado">Certificado de Egresado</option>
          <option value="Titulo Profesional">Titulo Profesional</option>
        </select>
      </div>
      <div className="input-field">
        <i className="fa-solid fa-money-bill-transfer"></i>
        <input
          type="text"
          name="Monto"
          placeholder="Monto"
          value={formData.Monto}
          onChange={handleChange}
        />
      </div>
      <div className="input-field">
        <i className="fa-solid fa-calendar-days"></i>
        <DatePicker
          selected={startDate}
          onChange={handleDateChange}
          placeholderText="Fecha"
          dateFormat="dd/MM/yyyy"
          className="date-picker-input"
        />
      </div>
      <div className="input-field">
        <i className="fa-solid fa-arrow-right"></i>
        <select
          className='select'
          name="tipoPago"
          value={formData.tipoPago}
          onChange={handleChange}
        >
          <option value="" disabled selected>Tipo de Pago</option>
          <option value="Efectivo">Efectivo</option>
          <option value="Targeta">Targeta</option>
          <option value="Yape">Yape</option>
        </select>
      </div>
      <input type="submit" className="btn" value="Registrar" />
    </form>
  );
};


const PanelContent = ({ title, description, buttonText, onClick }) => (
  <div className="content">
    <h3>{title}</h3>
    <p>{description}</p>
    <button className="btn transparent" onClick={onClick}>
      {buttonText}
    </button>
  </div>
);

const Registro = () => {
  const [isSignUpMode, setIsSignUpMode] = useState(true); 

  return (
    <div>
      <Header/>
      <div className={`container ${isSignUpMode ? 'sign-up-mode' : ''}`}>
        <div className="forms-container">
          <div className="signin-signup">
            <SignInForm />
            <SignUpForm />
          </div>
        </div>
        <div className="panels-container">
          <div className="panel left-panel">
            <PanelContent
              title=""
              
              buttonText="Registrar Pago"
              onClick={() => setIsSignUpMode(true)}
            />
            <img src="img/cohete1.svg" className="image" alt="" id="cohete" />
          </div>
          <div className="panel right-panel">
            <PanelContent
              title=""
              
              buttonText="Registrar Estudiante"
              onClick={() => setIsSignUpMode(false)}
            />
            <img src="img/esfera.svg" className="image" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registro;
    