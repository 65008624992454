import React from 'react';
import Registro from '../../Components/Registro/Registros'

const ViewRegistro=()=>{

    return(
    <div>
        <Registro/>
    </div>
    )
}
export default ViewRegistro;