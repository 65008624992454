import styles from './Welcome.module.css';

const Welcome = () => {
    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>BIENVENIDO AL SISTEMA DE CONTROL DE PAGOS</h1>
        </div>
    );
}

export default Welcome;
