import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import style from './RegistroPagos.module.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import Header from '../../Views/Header/Header';

const apiUrl = process.env.REACT_APP_API_URL; 
const RegistroPagos = () => {
  const [registros, setRegistros] = useState([]);
  const [students, setStudents] = useState([]);
  const [carreras, setCarreras] = useState([]);
  const [registroPagos, setRegistroPagos] = useState([]);
  const [editingRegistro, setEditingRegistro] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  let navigate = useNavigate();
  const { id } = useParams();
  console.log("This es el ID student:",id)

  useEffect(() => {
    const fetchCarreras = async () => {
      try {
        const response = await axios.get(apiUrl+'/Carreras');
        setCarreras(response.data);
      } catch (error) {
        console.error("Error fetching carreras:", error);
      }
    };

    const fetchStudents = async () => {
      try {
        const response = await axios.get(apiUrl+'/Student');
        setStudents(response.data);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };

    const fetchRegistroPagos = async () => {
      try {
        const response = await axios.get(apiUrl+'/registroPago');
        setRegistroPagos(response.data);
      } catch (error) {
        console.error("Error fetching registroPagos:", error);
      }
    };

    fetchCarreras();
    fetchStudents();
    fetchRegistroPagos();
  }, []);

  console.log("Registro de pagossssssssssssssssssss:", registroPagos)  
  console.log("Estudiantes del estado:",students)
  const registro = registroPagos.filter(e => e.dni == id);
  console.log("Esto es el registro que coincide con el estudiante:",registro)
  const student = students.find(e => e.dni === id);
  console.log("Current student:",student)
  const carrera = carreras.find(e => e.idCarrera === student?.idCarrera);

  const conceptos = [
    "Matricula I Ciclo",
    "Matricula II Ciclo",
    "Matricula III Ciclo",
    "Matricula IV Ciclo",
    "Matricula V Ciclo",
    "Matricula VI Ciclo",
    "Modulo I de Practicas",
    "Modulo II de Practicas",
    "Modulo III de Practicas",
    "Certificado de Estudio",
    "Certificado de Ingles",
    "Certificado de Egresado",
    "Titulo Profesional"
  ];

  // const formatDate = (dateString) => {
  //   const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  //   return new Date(dateString).toLocaleDateString('es-ES', options);
  // };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset()); // Ajusta la fecha a la zona horaria local
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('es-ES', options);
  };
  

  const handleClickBorrar = async (idPago) => {
    try {
      await axios.delete(`${apiUrl}/registroPago/${idPago}`);
      setRegistroPagos(registroPagos.filter(r => r.idPago !== idPago));
      Swal.fire('Eliminado!', 'Registro eliminado con éxito!', 'success');
    } catch (error) {
      Swal.fire('Error!', 'Hubo un problema al eliminar el registro.', 'error');
    }
  };

  const handleClickEditar = (registro) => {
    setEditingRegistro(registro);
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingRegistro({
      ...editingRegistro,
      [name]: value,
    });
  };

  const handleUpdateRegistro = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${apiUrl}/registroPago/${editingRegistro.idPago}`, editingRegistro);
      setRegistroPagos(registroPagos.map(r => (r.idPago === editingRegistro.idPago ? editingRegistro : r)));
      setIsEditing(false);
      Swal.fire('Actualizado!', 'Registro actualizado con éxito!', 'success');
    } catch (error) {
      Swal.fire('Error!', 'Hubo un problema al actualizar el registro.', 'error');
    }
  };

  const handleClickRealizarPagos = () => {
    navigate('/registro');
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text(`Registro de Pagos de ${student.firstName} ${student.lastName}`, 10, 10);
    doc.text(`Carrera: ${carrera?.nombre}`, 10, 20);

    const tableColumn = ["Concepto", "Monto", "Fecha", "Estado de Pago"];
    const tableRows = [];

    conceptos.forEach(concepto => {
      const registroc = registro.find(m => m.MotivoPago === concepto);
      const row = [
        concepto,
        registroc ? registroc.Monto : '',
        registroc ? formatDate(registroc.fecha) : '',
        registroc ? (registroc.estadoPago ? 'Pagado' : 'Pendiente') : 'Pendiente'
      ];
      tableRows.push(row);
    });

    doc.autoTable({
      startY: 30,
      head: [tableColumn],
      body: tableRows,
    });

    doc.save('registro_pagos.pdf');
  };

  return (
    <div>
        <Header/> 
    <div className={style.containerReportePago}>
      
      
      
      <div className={style.data_student}>
        <div className={style.student_foto}>
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/330px-User_icon_2.svg.png" alt="" className={style.foto} />
        </div>
        <div className={style.description_student}>
          {student && (
            <>
              <h5 className={style.nombre}>{student.lastName} {student.firstName}</h5>
              <h5 className={style.carrera}>Carrera : {carrera?.nombre}</h5>
            </>
          )}
        </div>
      </div>

      <button onClick={generatePDF} className={style.pdfButton}>Generar PDF</button>

      <div>
        <table className={style.table}>
          <thead>
            <tr>
              <th>Concepto</th>
              <th>Código de pago</th>
              <th>Monto</th>
              <th>Fecha</th>
              <th>Estado de Pago</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {conceptos.map(concepto => {
              const registroc = registro.find(m => m.MotivoPago === concepto);
              return (
                <tr key={concepto}>
                  <td>{concepto}</td>
                  <td>{registroc ? registroc.codigoPago : ''}</td>
                  <td>{registroc ? registroc.Monto : ''}</td>
                  <td>{registroc ? formatDate(registroc.fecha) : ''}</td>
                  <td>{registroc ? (registroc.estadoPago ? 'Pagado' : 'Pendiente') : 'Pendiente'}</td>
                  <td>
                    {registroc ? (
                      <>
                        <button onClick={() => handleClickEditar(registroc)}>Editar</button>
                        <button onClick={() => handleClickBorrar(registroc.idPago)}>Eliminar</button>
                      </>
                    ) : (
                      <button onClick={() => handleClickRealizarPagos()}>Realizar Pago</button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {isEditing && editingRegistro && (
        <div className={style.editForm}>
          <h3>Editar Registro</h3>
          <form onSubmit={handleUpdateRegistro}>
            <div>
              <label>Concepto:</label>
              <input type="text" name="MotivoPago" value={editingRegistro.MotivoPago} onChange={handleInputChange} disabled />
            </div>
            <div>
              <label>Monto:</label>
              <input type="number" name="Monto" value={editingRegistro.Monto} onChange={handleInputChange} />
            </div>
            <div>
              <label>Fecha:</label>
              <input type="date" name="fecha" value={editingRegistro.fecha} onChange={handleInputChange} />
            </div>
            
            <button type="submit">Actualizar</button>
            <button type="button" onClick={() => setIsEditing(false)}>Cancelar</button>
          </form>
        </div>
      )}
    </div>
    </div>
    
  );
}

export default RegistroPagos;
