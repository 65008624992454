import { createContext, useReducer, useEffect } from "react";
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL; 
const DataContext = createContext();

const initialState = {
    carreras: [],
    users: [],
    registroPagos: [],
    students: [],
    loading: true,
    error: null,
};

const dataReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_CARRERAS':
            return { ...state, carreras: action.payload, loading: false };
        case 'FETCH_USERS':
            return { ...state, users: action.payload, loading: false };
        case 'FETCH_REGISTRO_PAGOS':
            return { ...state, registroPagos: action.payload, loading: false };
        case 'FETCH_STUDENTS':
            return { ...state, students: action.payload, loading: false };
        case 'FETCH_ERROR':
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

const DataProvider = ({ children }) => {
    
    const [state, dispatch] = useReducer(dataReducer, initialState);

    useEffect(() => {
        const fetchCarreras = async () => {
            try {
                const response = await axios.get(apiUrl+'/Carreras');
                dispatch({ type: 'FETCH_CARRERAS', payload: response.data });
            } catch (error) {
                dispatch({ type: 'FETCH_ERROR', payload: error.message });
            }
        };

        const fetchUsers = async () => {
            try {
                const response = await axios.get(apiUrl+'/Users');
                dispatch({ type: 'FETCH_USERS', payload: response.data });
            } catch (error) {
                dispatch({ type: 'FETCH_ERROR', payload: error.message });
            }
        };

        const fetchRegistroPagos = async () => {
            try {
                const response = await axios.get(apiUrl+'/registroPago');
                dispatch({ type: 'FETCH_REGISTRO_PAGOS', payload: response.data });
            } catch (error) {
                dispatch({ type: 'FETCH_ERROR', payload: error.message });
            }
        };

        const fetchStudents = async () => {
            try {
                const response = await axios.get(apiUrl+'/Student');
                dispatch({ type: 'FETCH_STUDENTS', payload: response.data });
            } catch (error) {
                dispatch({ type: 'FETCH_ERROR', payload: error.message });
            }
        };

        fetchCarreras();
        fetchUsers();
        fetchRegistroPagos();
        fetchStudents();
    }, [dispatch]); 

    return (
        <DataContext.Provider value={{ state, dispatch }}>
            {children}
        </DataContext.Provider>
    );
};

export { DataContext, DataProvider };
