
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { DataProvider } from './Components/ConfiState/DataContext';

import ViewStudent from './Views/Student/ViewStudent';
import ViewRegistro from './Views/Registro/ViewRegistro';

import Login from './Components/Login/Login';
import Home from "./Views/Home/Home";
import RegistroPagos from './Components/Reporte/RegistroPagos';

import Registro from './Components/ingresarDatos/ingresarDatosRegistro';
import RegistrarCarreras from './Components/ingresarDatos/ingresarCarreras';
import RegistrarStudent from './Components/ingresarDatos/IngresarStudiantes';

const Ingresardatos = () => {
  return (
    <div>
      <Registro />
      <RegistrarCarreras />
      <RegistrarStudent />
    </div>
  );
}

function App() {
  return (
    <DataProvider>
      <div>
        <BrowserRouter>
          <Routes>
            <Route exact path="/student" element={<ViewStudent />} />
            <Route path="/" element={<Login />} />
            <Route path="/Home" element={<Home />} />
            <Route path='/registro' element={<ViewRegistro />} />
            <Route path='/registroPagos/:id' element={<RegistroPagos />} />
          </Routes>
        </BrowserRouter>
      </div>
    </DataProvider>
  );
}

export default App;


