import React, { useState } from 'react';
import style from './Select.module.css';
import Carreras from './Carreras';

function Select({ filter, onInputChange, onOptionClick, students, onCarreraChange }) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleBlur = () => {
    setTimeout(() => setIsDropdownVisible(false), 200);
  };

  const filteredStudents = students.filter(student =>
    `${student.firstName} ${student.lastName}`.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div className={style.container}>
      <div>
        <Carreras onChange={onCarreraChange} />
      </div>
      <div>
        
        <input
          type="text"
          placeholder="Buscar estudiante..."
          value={filter}
          onChange={onInputChange}
          onFocus={() => setIsDropdownVisible(true)}
          onBlur={handleBlur}
          className={style.input}
        />
       
      </div>
    </div>
  );
}

export default Select;
