import React from 'react';
import Style from './ListStudent.module.css';
import { useNavigate } from 'react-router-dom';

function ListStudent({ students }) {
  let navigate = useNavigate();

  const handleClickRegistroPagos = (idEstudiante) => {
    navigate(`/registroPagos/${idEstudiante}`);
  }

  return (
    <div className={Style.containerStudent}>
      {students.map(student => (
        <div className={Style.card} key={student.idEstudiante}>
          <div className={Style.card_header}>
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/330px-User_icon_2.svg.png" alt="Foto del estudiante" className={Style.profile_img} />
          </div>
          <div className={Style.card_body}>
            <h2 className={Style.student_name}>{student.firstName} {student.lastName}</h2>
            <p className={Style.student_info}><strong>ID:</strong> {student.dni}</p>
            <p className={Style.student_info}><strong>ID Carrera:</strong> {student.idCarrera}</p>
            <p className={Style.student_info}><strong>Edad:</strong> {student.age} años</p>
            <p className={Style.student_info}><strong>Correo:</strong> {student.email}</p>
            <p className={Style.student_info}><strong>DNI:</strong> {student.dni}</p>
          </div>
          <div className={Style.card_footer}>
            <div className={Style.btn_registro}>
              <a className={Style.a} onClick={() => handleClickRegistroPagos(student.dni)}>Ver</a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ListStudent;

