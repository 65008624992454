import React, { useContext } from 'react';
import { DataContext } from '../ConfiState/DataContext';


function Carreras({ onChange }) {
  const { state } = useContext(DataContext);
  const { carreras, loading, error } = state;

  return (
    <div>
      
      <select onChange={onChange} >
        <option value="">Todas las carreras</option>
        {carreras.map((carrera) => (
          <option key={carrera.idCarrera} value={carrera.idCarrera}>
            {carrera.nombre}
          </option>
        ))}
      </select>
      {loading && <p>Cargando carreras...</p>}
      {error && <p>Error al cargar carreras</p>}
    </div>
  );
}

export default Carreras;
