import React from 'react';
import style from './Header.module.css';
import { useNavigate } from 'react-router-dom';

function Header() {
  let navigate = useNavigate();

  const handleClickHome = () => {
    navigate('/home'); // Asegúrate de que la ruta coincide con la configuración de tus rutas
  };
  
  const handleClickStudent = () => {
    navigate('/student');
  };
  
  const handleClickRegistro = () => {
    navigate('/registro');
  };
  
  const handleClickReporte = () => {
    navigate('/reporte');
  };

  return (
    <div>
      <section>
        <div className={style.container}>
          <header className={style.navbar}>
            <a><img src="https://institutosuiza.edu.pe/wp-content/uploads/2023/08/Logo-suiza.png" alt="logo dsi" className={style.insignia} /></a>
            <a href="/" className={style.logo}>I.E.S.T.P Suiza</a>
            
            <ul>
              <li><a onClick={handleClickHome}>Home</a></li>
              <li><a onClick={handleClickStudent}>Estudiantes</a></li>
              <li><a onClick={handleClickRegistro}>Registros</a></li>
             
            </ul>
          </header>
        </div>
      </section>
    </div>
  );
}

export default Header;
