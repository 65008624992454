import Header from '../Header/Header'
import Welcome from '../../Components/Welcome/Welcome';

const Home=()=>{

    return(
        <div>
            <Header/>
            <Welcome/>
            
        </div>
    )
}
export default Home;