import React, { useState, useContext, useEffect } from 'react';
import { DataContext } from '../../Components/ConfiState/DataContext';

import Header from '../Header/Header';
import Select from '../../Components/Filtros/SelectStudent';
import ListStudent from '../../Components/Student/ListStudent';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL; 

const ViewStudent = () => {
  const { state, dispatch } = useContext(DataContext);
  const { students } = state;

  const [filter, setFilter] = useState('');
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedCarrera, setSelectedCarrera] = useState('');

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axios.get(apiUrl+'/Student');
        dispatch({ type: 'FETCH_STUDENTS', payload: response.data });
        console.log("Disparando petición para traer a los estudiantes de la DB")
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    };
    fetchStudents();
  }, [dispatch]);

    console.log(students)
  
  const handleInputChange = (e) => {
    setFilter(e.target.value);
    setSelectedStudent(null); // Reset selected student on filter change
  };

  const handleOptionClick = (student) => {
    setFilter(`${student.firstName} ${student.lastName}`);
    setSelectedStudent(student);
  };

  const handleCarreraChange = (e) => {
    setSelectedCarrera(e.target.value);
  };

  const filteredStudents = students.filter(student => {
    const matchesName = `${student.firstName} ${student.lastName}`.toLowerCase().includes(filter.toLowerCase());
    const matchesCarrera = selectedCarrera === '' || student.idCarrera === parseInt(selectedCarrera);
    return matchesName && matchesCarrera;
  });

  return (
    <div>
      <Header />
      <>
      <Select
        filter={filter}
        onInputChange={handleInputChange}
        onOptionClick={handleOptionClick}
        students={students}
        selectedStudent={selectedStudent}
        onCarreraChange={handleCarreraChange}
      />
      </>
      
      <ListStudent
        students={selectedStudent ? [selectedStudent] : filteredStudents}
      />
    </div>
  );
};

export default ViewStudent;
