import React, { useState,useContext } from 'react';

import { DataContext } from '../ConfiState/DataContext';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Asegúrate de importar tu archivo CSS
import '@fortawesome/fontawesome-free/css/all.min.css';

const apiUrl = process.env.REACT_APP_API_URL; 

const SignInForm = () => {
 
  const { state } = useContext(DataContext);
  const { users } = state;

  const [formData, setFormData] = useState({
    users: '',
    contraseña: ''
  });

  let navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Encontrar el usuario por el campo user
    const user = users.find(u => u.users === formData.users);

    if (user && user.contraseña === formData.contraseña) {
      // Usuario y contraseña coinciden
      navigate('/Home');
    } else {
      // Usuario no encontrado o contraseña incorrecta
      alert('Usuario o contraseña incorrectos');
    }
  };

  return (
    <form className="sign-in-form" onSubmit={handleSubmit}>
      <h2 className="title">Iniciar sesión</h2>
      <div className="input-field">
        <i className="fas fa-user"></i>
        <input 
          type="text" 
          placeholder="Username" 
          name="users"
          value={formData.users}
          onChange={handleChange} 
        />
      </div>
      <div className="input-field">
        <i className="fas fa-lock"></i>
        <input 
          type="password" 
          placeholder="Password" 
          name="contraseña"
          value={formData.contraseña}
          onChange={handleChange} 
        />
      </div>
      <input type="submit" value="Iniciar sesión" className="btn solid" />
    </form>
  );
};

const SignUpForm = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    users: '',
    contraseña: '',
    adminCode: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleClickRegistrarUsuario = async (e) => {
    
    e.preventDefault();

    // Verificar el código de administrador
    const validAdminCode = "1234"; // Reemplaza esto con tu código de administrador válido
    if (formData.adminCode !== validAdminCode) {
      Swal.fire('Error!', 'Código de administrador incorrecto.', 'error');
      return;
    }

    // Crear un nuevo objeto sin el código de administrador para enviarlo al backend
    const { adminCode, ...userData } = formData;

    try {
      const response = await axios.post(apiUrl+'/Users', userData);
     
        Swal.fire('Registrado!', 'Usuario registrado con éxito!', 'success');
        navigate('/home')
      
    } catch (error) {
      Swal.fire('Error!', 'Hubo un problema al registrar el usuario.', 'error');
    }
  };

  return (
    <form className="sign-up-form" onSubmit={handleClickRegistrarUsuario}>
      <h2 className="title">Crear cuenta</h2>
      <div className="input-field">
        <i className="fas fa-user"></i>
        <input
          type="text"
          placeholder="Username"
          name="users"
          value={formData.users}
          onChange={handleChange}
        />
      </div>
      <div className="input-field">
        <i className="fas fa-lock"></i>
        <input
          type="password"
          placeholder="Password"
          name="contraseña"
          value={formData.contraseña}
          onChange={handleChange}
        />
      </div>
      <div className="input-field">
        <i className="fas fa-lock"></i>
        <input
          type="password"
          placeholder="CodigoAdmin"
          name="adminCode"
          value={formData.adminCode}
          onChange={handleChange}
        />
      </div>
      <input type="submit" className="btn" value="Registrar" />
    </form>
  );
};

const PanelContent = ({ title, description, buttonText, onClick }) => (
  <div className="content">
    <h3>{title}</h3>
    <p>{description}</p>
    <button className="btn transparent" onClick={onClick}>
      {buttonText}
    </button>
  </div>
);

const Login = () => {
  const [isSignUpMode, setIsSignUpMode] = useState(false);

  return (
    <div className={`container ${isSignUpMode ? 'sign-up-mode' : ''}`}>
      <div className="forms-container">
        <div className="signin-signup">
          <SignInForm />
          <SignUpForm />
        </div>
      </div>
      <div className="panels-container">
        <div className="panel left-panel">
          <PanelContent
            title="¿Nuevo aquí?"
            
            buttonText="Registrate"
            onClick={() => setIsSignUpMode(true)}
          />
          <img src="https://institutosuiza.edu.pe/wp-content/uploads/2023/08/Logo-suiza.png" className="image" alt="" id="logo" />
        </div>
        <div className="panel right-panel">
          <PanelContent
            title="Uno de nosotros"
            
            buttonText="Iniciar sesión"
            onClick={() => setIsSignUpMode(false)}
          />
          <img src="https://institutosuiza.edu.pe/wp-content/uploads/2023/08/Logo-suiza.png" className="image" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Login;
